(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games/assets/javascripts/services/remove-game.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games/assets/javascripts/services/remove-game.js');
"use strict";


const removeGame = (activatedDrawId, callback) => {
  svs.core.jupiter.delete("/marketplace/1/containers/".concat(activatedDrawId), () => {
    callback(undefined);
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.games.services.removeGame', removeGame);

 })(window);