(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games/assets/javascripts/games-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games/assets/javascripts/games-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  log
} = svs.core;
const {
  analytics
} = svs.components;
const {
  services
} = svs.components.marketplaceData.games;
const {
  dialogWrapper,
  selectors,
  serviceWithRetrial,
  errorCodeHandler
} = svs.components.marketplaceData;
const logger = new log.Logger('marketplace-data:games');
const GAMES_FETCH_INIT = 'GAMES/GAMES_FETCH_INIT';
const GAMES_FETCH_SUCCESS = 'GAMES/GAMES_FETCH_SUCCESS';
const GAMES_FETCH_FAILURE = 'GAMES/GAMES_FETCH_FAILURE';
const fetchGamesAction = _ref => {
  let {
    groupId,
    offset = 0,
    filter = {}
  } = _ref;
  return (dispatch, getState) => {
    dispatch({
      type: GAMES_FETCH_INIT,
      filter,
      groupId,
      offset
    });
    services.fetchGames({
      groupId,
      offset,
      filter,
      excludedIds: getState().GamesExcludeFetch || [],
      callback: (error, games, activeGamesFetched, activeGamesFetchable) => {
        if (error) {
          dispatch(dialogWrapper.actions.showDialog({
            title: 'Vi kunde inte hämta lagets spel, därför kan information vara missvisande.',
            type: 'error'
          }));
          dispatch({
            type: GAMES_FETCH_FAILURE,
            error,
            groupId
          });
        } else {
          dispatch({
            type: GAMES_FETCH_SUCCESS,
            games,
            groupId,
            activeGamesFetched,
            activeGamesFetchable
          });
        }
      }
    });
  };
};
const GAME_FETCH_INIT = 'GAMES/GAME_FETCH_INIT';
const GAME_FETCH_FAILURE = 'GAMES/GAME_FETCH_FAILURE';
const GAME_FETCH_SUCCESS = 'GAMES/GAME_FETCH_SUCCESS';
const fetchGameAction = function (groupId, containerId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return dispatch => {
    const {
      warnings
    } = errorCodeHandler.constants;
    dispatch({
      type: GAME_FETCH_INIT,
      containerId,
      groupId
    });
    serviceWithRetrial.withRetrial(services.fetchContainer, {
      args: [groupId, containerId],
      retrials: options.doRetry ? 2 : -1,
      retrialWarnings: [warnings.RESOURCE_NOT_FOUND],
      delay: {
        base: 500,
        increment: 500
      }
    }, (err, attempt) => {
      logger.debug("Failed to fetch resource, retrying... ".concat(attempt > 0 ? "(attempt: ".concat(attempt, ")") : ''));
    }, (error, game) => {
      if (error) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Spelet kunde inte visas.',
          type: 'error'
        }));
        return dispatch({
          type: GAME_FETCH_FAILURE,
          error,
          containerId,
          groupId
        });
      }
      dispatch({
        type: GAME_FETCH_SUCCESS,
        game,
        containerId,
        groupId
      });
    });
  };
};
const ACTIVATED_DRAW_FETCH_INIT = 'GAMES/ACTIVATED_DRAW_FETCH_INIT';
const ACTIVATED_DRAW_FETCH_FAILURE = 'GAMES/ACTIVATED_DRAW_FETCH_FAILURE';
const ACTIVATED_DRAW_FETCH_SUCCESS = 'GAMES/ACTIVATED_DRAW_FETCH_SUCCESS';
const fetchActivatedDrawAction = function (groupId, activatedDrawId, containerId) {
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return dispatch => {
    const {
      warnings
    } = errorCodeHandler.constants;
    dispatch({
      type: ACTIVATED_DRAW_FETCH_INIT,
      containerId,
      activatedDrawId,
      groupId
    });
    serviceWithRetrial.withRetrial(services.fetchActivatedDraw, {
      args: [groupId, activatedDrawId, containerId],
      retrials: options.doRetry ? 2 : -1,
      retrialWarnings: [warnings.RESOURCE_NOT_FOUND],
      delay: {
        base: 500,
        increment: 500
      }
    }, (err, attempt) => {
      logger.debug("Failed to fetch resource, retrying... ".concat(attempt > 0 ? "(attempt: ".concat(attempt, ")") : ''));
    }, (error, game) => {
      if (error) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Spelet kunde inte visas.',
          type: 'error'
        }));
        return dispatch({
          type: ACTIVATED_DRAW_FETCH_FAILURE,
          error,
          activatedDrawId,
          containerId,
          groupId
        });
      }
      dispatch({
        type: ACTIVATED_DRAW_FETCH_SUCCESS,
        game,
        activatedDrawId,
        containerId,
        groupId
      });
    });
  };
};
const FETCH_SETTLED = 'GAMES/FETCH_SETTLED';
const FETCH_SETTLED_FAILURE = 'GAMES/FETCH_SETTLED_FAILURE';
const FETCH_SETTLED_SUCCESS = 'GAMES/FETCH_SETTLED_SUCCESS';
const fetchSettledBet = (groupId, activatedDrawId) => dispatch => {
  dispatch({
    type: FETCH_SETTLED,
    activatedDrawId,
    groupId
  });
  services.fetchGameHistory(activatedDrawId, (error, game) => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelet kunde inte visas.',
        type: 'error'
      }));
      return dispatch({
        type: FETCH_SETTLED_FAILURE,
        error,
        activatedDrawId,
        groupId
      });
    }
    dispatch({
      type: FETCH_SETTLED_SUCCESS,
      game,
      activatedDrawId,
      groupId
    });
  });
};
const REMOVE_GAME_INIT = 'GAMES/REMOVE_GAME_INIT';
const REMOVE_GAME_FAILED = 'GAMES/REMOVE_GAME_FAILED';
const REMOVE_GAME_SUCCESS = 'GAMES/REMOVE_GAME_SUCCESS';
const removeGameAction = (groupId, containerId, callback) => (dispatch, getState) => {
  const {
    activatedDrawId
  } = selectors.games.getGameByContainerId(getState(), groupId, containerId);
  dispatch({
    type: REMOVE_GAME_INIT,
    groupId,
    containerId,
    activatedDrawId
  });
  services.removeGame(containerId, error => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: REMOVE_GAME_FAILED,
          groupId,
          containerId,
          activatedDrawId,
          error
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: err.message,
          type: 'error'
        }));
        callback(err);
      });
    } else {
      dispatch({
        type: REMOVE_GAME_SUCCESS,
        groupId,
        containerId,
        activatedDrawId
      });
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelet är borttaget.',
        icon: 'checkmark'
      }));
      analytics.trackEvent({
        category: 'Tillsammans',
        action: 'Cancel Bet'
      });
      callback();
    }
  });
};
const GAMES_HISTORY_FETCH_INIT = 'GAMES/GAMES_HISTORY_FETCH_INIT';
const GAMES_HISTORY_FETCH_SUCCESS = 'GAMES/GAMES_HISTORY_FETCH_SUCCESS';
const GAMES_HISTORY_FETCH_FAILURE = 'GAMES/GAMES_HISTORY_FETCH_FAILURE';
const fetchGamesHistoryAction = function (groupId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (dispatch, getState) => {
    const me = selectors.members.selectMe(getState(), {
      groupId
    }) || {};
    dispatch({
      type: GAMES_HISTORY_FETCH_INIT,
      groupId
    });
    const gamesHistoryData = selectors.games.selectGamesHistory(getState(), {
      groupId
    });
    const offset = options.offset !== undefined ? options.offset : gamesHistoryData.length;
    services.fetchGamesHistory(groupId, me.id, offset, 5, (error, res) => {
      if (error) {
        dispatch({
          type: GAMES_HISTORY_FETCH_FAILURE,
          error,
          errorMessage: 'Vi kunde inte hämta spelhistoriken.',
          groupId
        });
      } else {
        dispatch(_objectSpread({
          type: GAMES_HISTORY_FETCH_SUCCESS,
          groupId
        }, res));
      }
    });
  };
};
setGlobal('svs.components.marketplaceData.games.actions', {
  GAMES_HISTORY_FETCH_INIT,
  GAMES_HISTORY_FETCH_SUCCESS,
  GAMES_HISTORY_FETCH_FAILURE,
  fetchGamesHistoryAction,
  ACTIVATED_DRAW_FETCH_INIT,
  ACTIVATED_DRAW_FETCH_SUCCESS,
  ACTIVATED_DRAW_FETCH_FAILURE,
  fetchActivatedDrawAction,
  GAMES_FETCH_INIT,
  GAMES_FETCH_SUCCESS,
  GAMES_FETCH_FAILURE,
  fetchGamesAction,
  GAME_FETCH_INIT,
  GAME_FETCH_SUCCESS,
  GAME_FETCH_FAILURE,
  fetchGameAction,
  FETCH_SETTLED,
  FETCH_SETTLED_FAILURE,
  FETCH_SETTLED_SUCCESS,
  fetchSettledBet,
  REMOVE_GAME_INIT,
  REMOVE_GAME_FAILED,
  REMOVE_GAME_SUCCESS,
  removeGameAction
});

 })(window);