(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games/assets/javascripts/services/fetch-game-history.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games/assets/javascripts/services/fetch-game-history.js');
"use strict";


const {
  normalize
} = svs.components.marketplaceData;
const {
  createCache
} = svs.components.marketplaceData.cachedJupiterGet;
const fetchGameHistory = (activatedDrawId, callback) => {
  fetchGameHistory.cachedRequest("/marketplace/1/activateddraws/".concat(activatedDrawId, "/settledbet"), response => callback(undefined, normalize.normalizeGames(undefined, response.history)[0]), error => {
    callback(error.responseJSON.error);
  });
};
fetchGameHistory.cachedRequest = createCache(1000);
setGlobal('svs.components.marketplaceData.games.services.fetchGameHistory', fetchGameHistory);

 })(window);