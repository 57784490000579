(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games/assets/javascripts/helpers/can-member-edit-game.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games/assets/javascripts/helpers/can-member-edit-game.js');
"use strict";


const {
  roles
} = svs.components.marketplaceData.auth;
const canMemberEditGame = (member, _ref) => {
  let {
    fractions,
    isSubmitted,
    drawCloseTime
  } = _ref;
  return member && fractions && roles.hasPermission(member.role, roles.PERMISSIONS.PREPARE_GAME) && !isSubmitted && fractions[member.id] > 0 && new Date() < drawCloseTime;
};
setGlobal('svs.components.marketplaceData.games.helpers.canMemberEditGame', canMemberEditGame);

 })(window);