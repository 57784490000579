(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games/assets/javascripts/services/fetch-container.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games/assets/javascripts/services/fetch-container.js');
"use strict";


const {
  normalize
} = svs.components.marketplaceData;
const {
  createCache
} = svs.components.marketplaceData.cachedJupiterGet;
const fetchContainer = (groupId, containerId, callback) => {
  const onSuccess = response => {
    callback(undefined, normalize.normalizeGames(undefined, response.containerWithDraw)[0]);
  };
  const onError = error => callback(error.responseJSON.error);
  const url = "/marketplace/1/container/".concat(containerId);
  fetchContainer.cachedRequest(url, onSuccess, onError);
};
fetchContainer.cachedRequest = createCache(1000);
setGlobal('svs.components.marketplaceData.games.services.fetchContainer', fetchContainer);

 })(window);