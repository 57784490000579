(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games/assets/javascripts/services/fetch-games.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games/assets/javascripts/services/fetch-games.js');
"use strict";


const {
  normalize,
  cachedJupiterGet
} = svs.components.marketplaceData;
const fetchGames = _ref => {
  let {
    groupId,
    excludedIds,
    callback,
    offset = 0,
    filter = {}
  } = _ref;
  let url = "/marketplace/3/groups/".concat(groupId, "/containers?offset=").concat(offset);
  const queryParams = Object.keys(filter).map(key => "".concat(key, "=").concat(filter[key].join(',')));
  if (queryParams.length > 0) {
    url += "&".concat(queryParams.join('&'));
  }
  fetchGames.cacheRequest(url, response => {
    const games = response.group ? response.group : response.containersWithDraw;
    const newlyFetchedGames = normalize.normalizeGames(response.currentUserCustomerId, ...games).filter(game => !excludedIds.includes(game.activatedDrawId));
    const activeGamesFetched = offset + games.length;
    const activeGamesFetchable = response.totalHits;
    callback(undefined, newlyFetchedGames, activeGamesFetched, activeGamesFetchable);
  }, error => {
    callback(error.responseJSON.error);
  });
};
fetchGames.cacheRequest = cachedJupiterGet.createCache(1000);
setGlobal('svs.components.marketplaceData.games.services.fetchGames', fetchGames);

 })(window);