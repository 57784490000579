(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games/assets/javascripts/services/cancel-wager.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games/assets/javascripts/services/cancel-wager.js');
"use strict";


const cancelWager = (wagerSerial, callback) => {
  svs.core.jupiter.delete("/marketplace/1/wagers/".concat(wagerSerial), () => {
    callback(undefined);
  }, () => {
    callback(new Error('Det gick inte att annullera spelet, försök igen senare.'));
  });
};
setGlobal('svs.components.marketplaceData.games.services.cancelWager', cancelWager);

 })(window);